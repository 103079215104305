import React, { useState } from "react";
import WebSocket from "./WebSocket";
import ScreenSelection, { getDefaultScreens } from "./ScreenSelection";
import Config from "../utils/config";

export default function Subtitles(props) {
  const [screens, setScreens] = useState(getDefaultScreens());

  function handleSelectChange(index, items) {
    let item = { ...items[index] };
    let atem = screens.filter((screen) => screen.id === "atem");
    if (atem && atem[0] && atem[0].enabled) {
      let ajax = new XMLHttpRequest();
      ajax.onreadystatechange = function () {
        if (ajax.readyState === 4) {
          if (
            ajax.status === 200 ||
            window.location.href.indexOf("http") === -1
          ) {
          }
        }
      };
      ajax.open(
        "POST",
        "http://" + Config.getAtemControlSoftwareIp() + ":8081/api/send_image",
        true
      );
      ajax.setRequestHeader("Content-type", "application/json");
      ajax.setRequestHeader(
        "Authorization",
        "Basic " + btoa("user:" + Config.getAtemControlSoftwarePassword())
      );
      ajax.send(
        JSON.stringify({
          atemHost: Config.getAtemMixerIp(),
          stillIndex: 1,
          layers: [
            {
              type: "text",
              text: item.name,
              fontSize: (atem[0].size * 60) / 100,
              fontShadow: 3,
              align: "center",
              valign: "bottom",
              left: 0.52,
              right: 0.52,
              top: 0.52,
              bottom: 7.41,
            },
          ],
        })
      );
    }
    let slide = { ...item.slide };
    slide.screens = { ...screens };
    let screenStageEnabled =
      screens.filter(
        (screen) =>
          (screen.id === "screen" || screen.id === "stage") && screen.enabled
      ).length > 0;
    let subtitlesEnabled =
      screens.filter((screen) => screen.id === "subtitles" && screen.enabled)
        .length > 0;
    if (screenStageEnabled) {
      if (item.slide) {
        WebSocket.emit("play", item.slide, props.items);
      } else if (item.name === "" && !item.slide) {
        WebSocket.emit("stop", item.slide, props.items);
      }
    }
    if (subtitlesEnabled) {
      let item1 = { ...item };
      item1.screens = [...screens];

      WebSocket.emit("subtitles", item1, items);
    }
  }

  let items = [];
  props.items
    .filter(
      (item) =>
        item.type === "lyrics" &&
        item.lyric &&
        item.lyric.Body &&
        item.lyric.Body.text
    )
    .forEach((item) => {
      if (!item.isChild) {
        let slideTitle =
          item &&
          item.lyric &&
          item.lyric.Title &&
          item.lyric.Title.text &&
          item.lyric.Title.text[0];
        items.push({
          _id: item._id,
          type: "subtitle",
          name: "",
          slide: item,
          slideTitle,
        });
      }
      item.lyric.Body.text.forEach((line, index) => {
        items.push({
          _id: item._id + "_" + index,
          type: "subtitle",
          name: line,
          slide: item,
        });
      });
    });
  items.push({ _id: "lastline", type: "subtitle", name: "" });
  return (
    <React.Fragment>
      <ScreenSelection
        screens={screens}
        onChange={(screens) => setScreens([...screens])}
      />
      <select
        className="flex-row"
        name="subtitles"
        size="11"
        onChange={({ currentTarget: input }) => {
          handleSelectChange(input.selectedIndex, items);
        }}
      >
        {items.map((item) => (
          <option
            value={item._id}
            key={item._id}
            style={{
              borderTop: item._id && item._id.endsWith("_0") ? "solid" : "",
              borderColor: "#d0d0d0",
              backgroundColor: item.name === "" && "#d0d0d0",
              paddingTop: item.name === "" && "15px",
            }}
          >
            {item.name !== ""
              ? item.name
              : item.slideTitle
              ? "=== " + item.slideTitle + " ==="
              : ""}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
}
